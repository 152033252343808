<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Firma Adı">
        firma adı
        destek adet
        görev adet
        hedef süre / toplam süre
      </b-card>
    </b-col>
    <b-col>
      <b-card
        title="Görevler"
      >
        görevler
        tiplere göre işlemler
      </b-card>
    </b-col>
    <b-col>
      <b-card title="Destek Kayıtları">
        destek kayıtları
        tiplere göre işlemler
      </b-card>
    </b-col>
    <b-col>
      <b-card title="Projeler">
        Projeler
        tiplere göre işlemler
      </b-card>
    </b-col>
    <b-col>
      <b-card title="Kullanıcılar">
        Projeler
        tiplere göre işlemler
      </b-card>
    </b-col>
  </b-row>
</template>
<script lang="ts">
import { BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'Dashboard',
  components: {
    BCard, BCol, BRow,
  },
}
</script>
